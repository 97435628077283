export enum Role {
  Admin = "Administrator",
  Admin_billing_export_CH = "CH admin that can read billing exports",
  Admin_professionals_export_CH = "CH admin that can read professionals exports",
  Admin_case_data_export_CH = "CH admin that can read case data exports",
  Admin_support_export_CH = "CH admin that can read support exports",
  Admin_billing_export_DE = "DE admin that can read billing exports",
  Admin_case_data_export_DE = "DE admin that can read case_data exports",
  Admin_support_export_DE = "DE admin that can read support exports",
  Admin_case_support_DE = "DE admin that can update case data through support",
  Admin_case_support_CH = "CH admin that can update case data through support",
  Admin_doctor_payouts_DE = "DE admin that can access doctor payouts",
  Admin_doctor_payouts_CH = "CH admin that can access doctor payouts",
}

export const idTokenRolesKey = "https://www.onlinedoctor.eu/roles";
